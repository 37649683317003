import { Consent } from "../../dataTypes/type-consent";

export class UcFortTagManagerService {
  private isTagExecuted: { [tag: string]: boolean } = {};

  public updateScriptsTypeForConsents(consents: Consent.Consent[], consentTemplates: Consent.ConsentTemplates) {
    consents.forEach((consent: Consent.Consent) => {
      const consentTemplate = consentTemplates[consent.templateId] && consentTemplates[consent.templateId][consent.version] ? consentTemplates[consent.templateId][consent.version] : null;
      this.updateScriptsTypeForConsent(consent, consentTemplate);
    });
  }

  public async updateScriptsTypeForConsent(consent: Consent.Consent, consentTemplate: Consent.ConsentTemplate) {
    if (!consent || !consentTemplate || !consentTemplate.dataProcessors) {
      return;
    }

    const scriptList: NodeListOf<HTMLScriptElement> = document.querySelectorAll(`[data-usercentrics='${consentTemplate.dataProcessors[0]}']`);
    if (scriptList && scriptList.length) {
      const scriptListArray: HTMLScriptElement[] = Array.from(scriptList);

      for (let scriptIndex = 0; scriptIndex < scriptListArray.length; scriptIndex++) {
        await this.updateScriptTypeForConsent(scriptListArray[scriptIndex], consent);
      }
    }
  }

  private async updateScriptTypeForConsent(scriptNode: HTMLScriptElement, consent: Consent.Consent) {
    if (consent.unsavedConsentStatus && scriptNode.getAttribute('Type') === 'text/plain') {
      if (this.isTagExecuted[scriptNode.getAttribute('src')]) {
        scriptNode.setAttribute('Type', 'text/javascript');
        return;
      }

      const parentNode: HTMLElement = scriptNode.parentNode as HTMLElement;
      const newNode: HTMLScriptElement = document.createElement('script');

      Object.keys(scriptNode.attributes).forEach(attributeIndex => {
        const attribute = scriptNode.attributes[attributeIndex].name;
        newNode.setAttribute(attribute, scriptNode.getAttribute(attribute));
      });

      newNode.setAttribute('Type', 'text/javascript');
      if (scriptNode.getAttribute('src')) {
        this.isTagExecuted[scriptNode.getAttribute('src')] = true;
      } else if (scriptNode.textContent) {
        newNode.textContent = scriptNode.textContent;
      }

      parentNode.removeChild(scriptNode);
      parentNode.appendChild(newNode);

      if (scriptNode.getAttribute('src')) {
        await this.loadScript(newNode);
      }
    } else if (!consent.unsavedConsentStatus) {
      scriptNode.setAttribute('Type', 'text/plain');
    } else if (scriptNode.getAttribute('src')) {
      this.isTagExecuted[scriptNode.getAttribute('src')] = true;
    }
  }

  private async loadScript(node: HTMLScriptElement) {
    return new Promise<any>((resolve) => {
      node.onload = () => {
        resolve();
      };

      node.onerror = () => {
        resolve();
      };
    });
  }

}
