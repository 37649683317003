'use strict';
import DataExchangeService from './ts/services/data-exchange.service';
import { WhitelistedDomainService } from './ts/services/utilities/whitelisted-domain.service';
import { UcFortTagManagerService } from './ts/services/utilities/tag-manager.service';

declare var HEADLESS;

const getSettingId = function (ids, previousSettings) {
    const settingsIds = ids.split(':');
    setSettingsIds(settingsIds);
    let settingIndex;
    // If only one item in array then choose it
    if (settingsIds.length === 1) {
        settingIndex = 0;
        // If previous SettingId is part of array then use it
    } else if (previousSettings && previousSettings.settingsId && settingsIds.indexOf(previousSettings.settingsId) > -1) {
        settingIndex = settingsIds.indexOf(previousSettings.settingsId);
        // else select random setting Id or search in ucSettings
    } else {
        settingIndex = Math.floor(Math.random() * settingsIds.length);
        const ucSettings = localStorage.getItem('ucSettings');
        if (ucSettings !== null) {
            try {
                const ucDecodedSettings = JSON.parse(ucSettings);
                for (let i = 0; i < settingsIds.length; i++) {
                    if (ucDecodedSettings.hasOwnProperty(settingsIds[i])) {
                        settingIndex = i;
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
    const settingsId = settingsIds[settingIndex];
    return settingsId;
};

const setSettingsIds = function (ids) {
    const uscStorage = localStorage.getItem('usercentrics');
    const uscDecoded = uscStorage ? JSON.parse(uscStorage) : {};
    uscDecoded.settingsIds = ids;
    localStorage.setItem('usercentrics', JSON.stringify(uscDecoded));
};

const getUsercentricField = function (fieldKey) {
    const uscStorage = localStorage.getItem('usercentrics');
    let output = null;
    if (!uscStorage) {
        return output;
    }
    const uscDecoded = JSON.parse(uscStorage);
    if (!uscDecoded || (typeof uscDecoded !== 'object') || !(uscDecoded.hasOwnProperty(fieldKey))) {
        return output;
    }
    output = uscDecoded[fieldKey];
    return output;
};

/**
 * Checks if a different app was already loaded and if so, aborts the execution with an error.
 */
const checkForLoadedApp = function () {
    const usercentricsStorage = localStorage.getItem('usercentrics');

    /**
     * This is used for testing and development purposes. When there is a key of
     * "blockLoading" in the localStorage under "usercentrics", the app won't load
     */
    let blockedByStorage = false;
    if (usercentricsStorage !== null) {
        try {
            const usercentricsStorageDecoded = JSON.parse(usercentricsStorage);
            if (typeof usercentricsStorageDecoded.blockLoading !== 'undefined') {
                blockedByStorage = usercentricsStorageDecoded.blockLoading;
            }
        } catch {

        }
    }
    if (typeof window.usercentrics.appLoaded !== 'undefined' && window.usercentrics.appLoaded
        || blockedByStorage) {
        const loadedSetting = window.usercentrics.settingsId;
        const err = 'Tried to load Usercentrics twice. (Setting ' +
            loadedSetting + ' already loaded) Second load is stopped';
        console.error(err);
        return true;
    }
    return false;
};

const syncScripts = function (baseUrl, localhost) {
    if (
        !window.usercentrics.settingsId &&
        !(window.usercentrics.paths && window.usercentrics.paths.settings)
    ) {
        throw new Error('No settingsId found');
    }

    // If settingsId is different from local storage, clear local storage and look for previously stored setting
    const settings = getUsercentricField('settings');
    if (settings) {
        if (window.usercentrics.settingsId && settings.settingsId !== window.usercentrics.settingsId) {
            localStorage.removeItem('usercentrics');
            localStorage.removeItem('ucConsents');
            const ucSettings = localStorage.getItem('ucSettings');
            if (ucSettings !== null) {
                try {
                    const ucDecodedSetting = JSON.parse(ucSettings);
                    if (ucDecodedSetting.hasOwnProperty(window.usercentrics.settingsId)) {
                        localStorage.setItem('usercentrics', JSON.stringify(ucDecodedSetting[window.usercentrics.settingsId].usercentrics));
                        localStorage.setItem('ucConsents', JSON.stringify(ucDecodedSetting[window.usercentrics.settingsId].ucConsents));
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
        initializeDataExchangeService(settings);
    }


    const version = window.usercentrics.version;

    const mainScript = document.createElement('script');
    mainScript.type = 'text/javascript';
    mainScript.async = true;

    //used for dev environment on local.
    const filename = HEADLESS ? 'bundle-headless.js' : 'bundle.js';
    if (window.usercentrics.paths && window.usercentrics.paths.bundle) {
        window.usercentrics.baseUrl = window.usercentrics.paths.bundle;
        mainScript.setAttribute('src', window.usercentrics.baseUrl);
    } else if (localhost) {
        window.localhost = true;
        if (WhitelistedDomainService.isLocalhost(window.location.hostname)) {
            baseUrl = window.location.origin;
        } else {
            baseUrl = 'http://localhost:8080';
        }
        window.usercentrics.baseUrl = baseUrl;
        mainScript.setAttribute('src', `${baseUrl}/${filename}`);
    } else {
        mainScript.setAttribute('src', `${baseUrl}/${version}/${filename}`);
    }
    document.head.appendChild(mainScript);
};

const myListener = function (baseUrl, localhost, listenerFunct) {
    document.removeEventListener('mousemove', listenerFunct, false);
    document.removeEventListener('touchstart', listenerFunct, false);
    document.removeEventListener('keyup', listenerFunct, false);
    syncScripts(baseUrl, localhost);
};

const getUscDecoded = () => {
    const uscStorage = localStorage.getItem('ucConsents');
    if (!uscStorage) {
        return null;
    }
    const uscDecoded = JSON.parse(uscStorage);
    if (!uscDecoded || (typeof uscDecoded !== 'object') || !(uscDecoded.hasOwnProperty('consents'))) {
        return null;
    }
    return uscDecoded;
}

/**
 * Get all the consents from local storage. Optionally filtered by a templateId
 * In case no consents have been stored yet, an empty array is returned.
 * @param templateId 
 */
const getConsents = (templateId?) => {
    const returnConsents = [];
    const uscDecoded = getUscDecoded();
    if (!uscDecoded) {
        return returnConsents;
    }
    const consents = uscDecoded.consents;
    // loop over all consents and merge information from template with consent
    consents.forEach((consent) => {

        // If optional templateId was provided, check if consent has this templateId
        if (typeof templateId !== 'undefined' && consent.templateId !== templateId) {
            return;
        }
        if (typeof uscDecoded.consentTemplates[consent.templateId] === 'undefined'
            || typeof uscDecoded.consentTemplates[consent.templateId][consent.version] === 'undefined') {
            return;
        }
        // Get the template
        const template = uscDecoded.consentTemplates[consent.templateId][consent.version];

        // Merge together template and consent
        returnConsents.push(Object.assign(template, consent));
    });

    // Sort by dataprocessors
    returnConsents.sort((a, b) => {
        var nameA = a.dataProcessors[0].toUpperCase();
        var nameB = b.dataProcessors[0].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
    });
    return (typeof templateId !== 'undefined' && returnConsents.length) ? returnConsents[0] : returnConsents;
};

const appendBundle = function (lazyload, baseUrl, localhost) {
    if (lazyload && (!localStorage || !localStorage.getItem('usercentrics'))) {
        const listenerFunct = () => myListener(baseUrl, localhost, listenerFunct);
        document.addEventListener('mousemove', listenerFunct, false);
        document.addEventListener('touchstart', listenerFunct, false);
        document.addEventListener('keyup', listenerFunct, false);
    } else if (window.usercentrics.isSinglePageApp) {
        window.addEventListener('load', function () {
            syncScripts(baseUrl, localhost);
        });
    } else if (document.head) {
        syncScripts(baseUrl, localhost);
    } else {
        document.addEventListener('DOMContentLoaded', function () {
            syncScripts(baseUrl, localhost);
        });
    }
};

/**
 * Initializes the dataExchangeService that is necessary for firing the datalayer events.
 * Only has an effect when there is data in the localStorage
 */
const initializeDataExchangeService = function (settings) {
    const uscDecoded = getUscDecoded();

    if (uscDecoded && uscDecoded.consents && settings) {
        const dataExchangeService = new DataExchangeService();
        dataExchangeService.dataExchangeArray = settings.dataExchangeOnPage;
        dataExchangeService.consentInitializedFromStorage(
            uscDecoded.consents, uscDecoded.consentTemplates
        );
        DataExchangeService.updatePrivacyProxy(uscDecoded.consents);
        const tagManagerService: UcFortTagManagerService = new UcFortTagManagerService();

        if (document.readyState === 'complete' || document.readyState === 'interactive') {
          tagManagerService.updateScriptsTypeForConsents(uscDecoded.consents, uscDecoded.consentTemplates);
        } else {
          document.addEventListener('DOMContentLoaded', () => {
            tagManagerService.updateScriptsTypeForConsents(uscDecoded.consents, uscDecoded.consentTemplates);
          });
        }
    }
};


const init = function () {
    window.usercentrics = window.usercentrics || {};
    window.usercentrics.getConsents = getConsents;

    // Check if a different setting was already loaded
    if (checkForLoadedApp()) {
        return;
    }
    window.usercentrics.appLoaded = true;

    // If settingsId is different from local storage, clear local storage and look for previously stored setting
    const settings = getUsercentricField('settings');
    if (window.usercentrics.settingsIds && !window.usercentrics.settingsId) {
        window.usercentrics.settingsId = getSettingId(window.usercentrics.settingsIds, settings);
    }

    let baseUrl = 'https://app.usercentrics.eu';
    let localhost = false;

    // Put code here
    let version = 'latest';
    if (window.usercentrics.paths && window.usercentrics.paths.bundle) {
        appendBundle(false, baseUrl, localhost);
    } else {
        const localhostURLRegex = /http:\/\/localhost:([0-9]){2,4}/;
        const localhostIPRegex = /http:\/\/10.0.2.2:([0-9]){2,4}/;

        const origin = window.location.origin ? window.location.origin : window.location.href;
        if (origin.match(localhostURLRegex) || window.localhost || origin.match(localhostIPRegex)) {
            baseUrl = origin;
            localhost = true;
            window.usercentrics.version = version;

            if (window.usercentrics.settingsIds) {
                window.usercentrics.settingsId = getSettingId(window.usercentrics.settingsIds, settings);
            } else if (window.usercentrics.settingsId) {
                window.usercentrics.settingsIds = window.usercentrics.settingsId;
            }

            appendBundle(false, baseUrl, localhost);
            return;
        }
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; i++) {
            const settingsId = scripts[i].getAttribute('id');
            const regexUrl = /https:\/\/app\.usercentrics\.eu\/([A-Za-z0-9_\-./])*\/main(-headless)?.js/g;
            if (scripts[i].src && scripts[i].src.match(regexUrl)) {
                localhost = false;
                const urlArray = scripts[i].src.split('/');

                if (urlArray.length > 4) {
                    version = urlArray[urlArray.length - 2];
                    baseUrl = scripts[i].src.slice(0, scripts[i].src.indexOf(version) - 1);
                }
                let _defaultLanguage = null;
                if (scripts[i].getAttribute('language')) {
                    _defaultLanguage = scripts[i].getAttribute('language');
                }
                if (scripts[i].dataset && typeof scripts[i].dataset.language !== 'undefined') {
                    _defaultLanguage = scripts[i].dataset.language;
                }
                if (typeof window.usercentrics.defaultLanguage !== 'undefined') {
                    _defaultLanguage = window.usercentrics.defaultLanguage
                }

                if (_defaultLanguage !== null) {

                    // Special case for website language
                    if (_defaultLanguage === 'automatic') {

                        // First look at the HTML element and check if "lang" attribute is set
                        if (document.documentElement && document.documentElement.lang && document.documentElement.lang != '') {
                            _defaultLanguage = document.documentElement.lang;

                            // Otherwise check meta tags
                        } else {
                            const metas = document.getElementsByTagName('meta');
                            for (let i = 0; i < metas.length; i++) {
                                if (metas[i].getAttribute('http-equiv') === 'language') {
                                    _defaultLanguage = metas[i].getAttribute('content');
                                }
                            }
                        }
                    }
                    // Make sure we have found a suitable language, otherwise we use standard behaviour (=browser language)
                    if (_defaultLanguage !== null && _defaultLanguage !== '' && _defaultLanguage !== 'automatic') {
                        // Splitting and lowercase is important for e.g. "en-in" or "DE"                
                        window.usercentrics.language = _defaultLanguage.split('-')[0].toLowerCase();
                    }
                }
                
                window.usercentrics.baseUrl = baseUrl;
                if (settingsId && typeof settingsId !== 'undefined') {
                    window.usercentrics.settingsIds = settingsId;
                    window.usercentrics.settingsId = getSettingId(settingsId, settings);
                } else if (window.usercentrics.settingsId) {
                    window.usercentrics.settingsIds = window.usercentrics.settingsId;
                }

                window.usercentrics.version = version;
                appendBundle(scripts[i].getAttribute('data-lazyload'), baseUrl, localhost);
                break;
            }
        }
        // Set the version outside of loop so that it is triggered even if loaded on localhost
        window.usercentrics.version = version;
    }
};

// Run
init();
